.redtextimage {
    margin-bottom: 100px;
    @media ( max-width: 600px ) {
        margin-bottom: 50px;
    }
    &__content {
        background-image: url('../images/bg-redpattern.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        max-width: 1440px;
        min-height: 512px;
        margin: 0 auto ;
        padding: 0 120px;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 20px 20px 50px 20px;
            min-height: 0;
        }
        &__inner {
            max-width: 800px;
            height: 392px;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media ( max-width: 600px ) {
                height: auto;
            }
        }
        &__title {
            font-family: "opensans", Helvetica, Arial, Sans-Serif;
            font-weight: 600;
            font-size: 60px;
            font-size: 4.17vw;
            line-height: 80px;
            line-height:  5.55vw;
            color: #FFF;
            text-align: center;
            margin-bottom: 40px;
            max-width: 100%;
            @media (max-width: 1024px) {
                font-size: 42px;
                line-height: 56px;
            }
            @media (max-width: 1024px) {
                font-size: 30px;
                line-height: 36px;
                margin-bottom: 20px;
            }
            @media ( min-width: 1440px ) {
                font-size: 60px;
                line-height: 80px;
            }
        }
        &__text {
            text-align: center;
            max-width: 100%;
            p {
                font-size: 18px;
                line-height: 30px;
                color: #FFF;
                text-align: center;
                margin-bottom: 0;
                @media ( max-width: 600px ) {
                    font-size: 14px;
                    line-height: 21px;
                }
                strong {
                    font-weight: 700;
                }
            }
        }
    }
    &__image {
        max-width: 1440px;
        margin: -120px auto 0 auto;
        padding: 0 120px;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
            margin: -25px auto 0 auto;
        }
        &__img {
            width: 100%;
        }
    }
}
