.text-image {
    width: 100%;
    margin: 0 auto 110px auto;
    @media ( max-width: 1024px ) {
        margin: 0 auto 80px auto;
    }
    @media ( max-width: 600px ) {
        margin: 0 auto 40px auto;
    }
    &__inner {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        @media ( max-width: 767px ) {
            flex-direction: column-reverse;
        }
    }
    &__content {
        width: 50%;
        padding: 80px 120px;
        @media (max-width: 1280px) {
            padding: 80px 40px;
        }
        @media ( max-width: 1024px ) {
            padding: 0 40px;
        }
        @media ( max-width: 767px ) {
            width: 100%;
            padding: 40px;
        }
        @media ( max-width: 600px ) {
            padding: 20px;
        }
        &__title {
            @include h2(400);
            color: #1C1D1E;
            margin-bottom: 40px;
            @media ( max-width: 600px ) {
                margin-bottom: 20px;
            }
            strong {
                font-weight: 600;
            }
        }
        &__text {
            p {
                font-size: 18px;
                line-height: 30px;
                color: #1C1D1E;
                margin-bottom: 30px;
                &:last-child {
                    margin-bottom: 0;
                }
                @media ( max-width: 600px ) {
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 20px;
                }
            }
            ul {
                margin: 0;
                padding: 0;
            }
            li {
                list-style: none;
                font-size: 20px;
                line-height: 30px;
                color: #37393E;
                opacity: 0.8;
                strong {
                    font-weight: 600;
                }
                @media ( max-width: 600px ) {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
    }
    &__image {
        width: 50%;
        @media ( max-width: 767px ) {
            width: 100%;
        }
        &__img {
            display: block;
            width: 100%;
        }
    }
    &--reverse {
        .text-image__inner {
            flex-direction: row-reverse;
            @media ( max-width: 767px ) {
                flex-direction: column-reverse;
            }
        }
        .text-image__content {
            padding: 80px 120px 80px 85px;
            @media (max-width: 1280px) {
                padding: 80px 40px;
            }
            @media ( max-width: 1024px ) {
                padding: 0 40px;
            }
            @media ( max-width: 767px ) {
                padding: 40px;
            }
            @media ( max-width: 600px ) {
                padding: 20px;
            }
        }
    }
}