.mail-contact {
    width: 100%;
    margin: 0 auto;
    padding: 70px 20px;
    background-color: #E6E9F0;
    @media ( max-width: 1024px ) {
        padding: 40px 20px;
    }
    @media ( max-width: 600px ) {
        padding: 20px;
    }
    &__inner {
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
        text-align: center;
    }
    &__title {
        min-height: 86px;
        background-image: url('../icons/mail-icon.svg');
        background-position: top left;
        background-repeat: no-repeat;
        @include h4(600);
        letter-spacing: -0.6px;
        padding-top: 30px;
        margin-bottom: 28px;
        @media ( max-width: 1024px ) {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 0;
            padding-top: 15px;
            min-height: 60px;
            background-size: auto 60px;
        }
    }
    &__text {   
        max-width: 600px;
        margin: 0 auto;
        p {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 30px;
            @media ( max-width: 1024px ) {
                margin-bottom: 15px;
            }
            .link {
                font-family: "opensans", Helvetica, Arial, Sans-Serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 30px;
                padding-top: 0;
                &:after {
                    background-color: rgba(255, 255, 255, 0.65);
                }
                &:hover {
                    &:after {
                        background-color: #EC0000;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
