.text-2cols {
    width: 100%;
    margin: 0 auto 120px auto;
    @media ( max-width: 768px ) {
        margin: 0 auto 60px auto;
    }
    @media ( max-width: 600px ) {
        margin: 0 auto;
    }
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 767px ) {
            flex-direction: column;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
    &__col {
        width: calc((100% - 50px) / 2);
        @media ( max-width: 767px ) {
            width: 100%;
            margin-bottom: 40px;
        }
        .title {
            max-width: 300px;
            @include h4(600);
            color: #1C1D1E;
            margin-bottom: 55px;
            @media ( max-width: 1024px ) {
                font-size: 21px;
                line-height: 28px;
                margin-bottom: 40px;
            }
            @media ( max-width: 767px ) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 20px;
                max-width: 100%;
            }
            &.big {
                @include h1(600);
            }
        }
        p {
            font-size: 18px;
            line-height: 30px;
            color: #1C1D1E;
            margin-bottom: 30px;
            @media ( max-width: 600px ) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 20px;
            }
            strong {
                font-weight: 600;
            }
            em {
                font-style: normal;
                color: #EC0000;
            }
        }
        .check-list {
            margin: 0;
            padding: 0;
            li {
                font-size: 18px;
                line-height: 40px;
                color: #1C1D1E;
                padding-left: 34px;
                position: relative;
                list-style-type: none;
                @media ( max-width: 600px ) {
                    font-size: 14px;
                    line-height: 21px;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 13px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    background-image: url("../images/icon-check.svg");
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                strong {
                    font-weight: 600;
                }
            }
        }
        .link {
            font-size: inherit;
            line-height: inherit;
            display: inline;
        }
    }
}

.aboutUsTemplate {
    .text-2cols__col {
        @media ( max-width: 767px ) {
            margin-bottom: 0;
        }
    }
}
