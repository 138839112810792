.benefit {
    width: 25%;
    height: 250px;
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media ( max-width: 991px ) {
        width: 33.333%;
    }
    @media ( max-width: 767px ) {
        height: auto;
        width: 50%;
        padding: 15px;
        margin-bottom: 20px;
    }
    &__icon {
        height: 80px;
        text-align: center;
        &__img {
            display: block;
            margin: 0 auto;
            max-height: 60px;
        }
    }
    &__text {
        text-align: center;
        max-width: 100%;
    }
}
