.modal-backdrop {
    background-color: #FFF;
}
.modal-backdrop.show {
    opacity: .75;
}
.modal {
    &-dialog {
        width: 90%;
        height: 90vh;
        max-width: 1000px;
        max-height: 1000px;
        background-color: #FFF;
        box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        overflow-y: scroll;
    }
    &-content {
        border: none;
        padding: 135px 135px 100px 100px;
        max-height: 90vh;
        overflow-y: scroll;
        @media ( max-width: 767px ) {
            padding: 50px;
        }
        @media ( max-width: 600px ) {
            padding: 20px;
        }
    }
    .close {
        position: absolute;
        top: 35px;
        right: 32px;
        width: 25px;
        height: 25px;
        background-image: url('../icons/close-icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        outline: none;
        opacity: 1;
        @media ( max-width: 767px ) {
            top: 15px;
            right: 15px;
        }
        @media ( max-width: 600px ) {
            width: 20px;
            height: 25px;
        }
        span {
            opacity: 0;
        }
    }
    &__title {
        @include h2(400);
        margin-bottom: 0;
    }
    &__subtitle {
        @include h4(600);
        margin-bottom: 25px;
    }
    &__location {
        font-size: 18px;
        line-height: 40px;
        font-weight: 600;
        color: #5A5D64;
        margin-bottom: 70px;
        @media ( max-width: 767px ) {
            margin-bottom: 30px;
        }
        &__img {
            width: 16px;
            margin-right: 15px;
        }
    }
    &__text {
        font-size: 18px;
        line-height: 30px;
        p {
            margin-bottom: 40px;
            @media ( max-width: 767px ) {
                margin-bottom: 30px;
            }
            strong {
                font-weight: 700;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        ul {
            margin-bottom: 90px;
            @media ( max-width: 767px ) {
                margin-bottom: 40px;
            }
        }
    }
}

/* modal animation */
.modal-content{
    animation-name: example;
    animation-duration: 0.3s;
}

.modal.fade .modal-dialog {
    overflow: hidden;
}

.modal__text.modal__jobs p {
    margin-bottom: 0;
}

.modal__text.modal__jobs ul {
    margin-bottom: 0;
}

@keyframes example {
    0%   {transform: scale(0.5)}
    75%  {transform: scale(1.1)}
    100% {transform: scale(1)}
}
